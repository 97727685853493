<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <router-view @refetchBikes="fetchBikes" />
            </v-col>
        </v-row>
        <v-row v-if="$route.name === 'BikeShop'">
            <!-- Filterbereich -->
            <v-col cols="5" md="12">
                <v-btn text class="mt-3" @click="goToRoody"><v-icon>mdi-chevron-left</v-icon> roody</v-btn>
            </v-col>
            <v-col align="center" justify="center" class="d-flex" cols="7" md="12" v-if="!$vuetify.breakpoint.mdAndUp">
                <v-btn class="my-auto mt-3" text color="primary" @click="viewOrders">
                    <v-icon class="mr-2">mdi-bike</v-icon>Reservierungen<v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-col>
            <v-col v-if="userService.hasRight('g_bikesAdmin')" align="end" cols="12" >
                <v-btn class="mb-5 white--text"  color="orange" @click="$router.push('/bikes/orders/admin')">
                    Admin Bestellungen
                </v-btn>
            </v-col>
            <v-col v-if="$vuetify.breakpoint.mdAndUp" align="center" cols="12" md="3">
                <v-btn class="mb-5" block color="primary" @click="viewOrders">
                    Meine Reservierungen
                </v-btn>

                <div v-if="$vuetify.breakpoint.mdAndUp">
                    <v-select class="mb-5" hide-details filled v-model="selectedCategory" :items="categories"
                        label="Kategorie" clearable></v-select>
                    <v-select class="mb-5" hide-details filled v-model="selectedBrand" :items="brands" label="Marke"
                        clearable></v-select>
                    <v-select class="mb-5" hide-details filled v-model="selectedHeight" :items="heights"
                        label="Geeignete Körpergrösse" clearable></v-select>
                    <v-select class="mb-5" hide-details filled v-model="selectedSize" :items="sizes" label="Grösse"
                        clearable></v-select>
                </div>

            </v-col>

            <!-- Bike-Anzeige -->
            <v-col cols="12" md="9">

                <v-row>
                    <v-col :class="{ 'd-flex justify-center': !$vuetify.breakpoint.mdAndUp }" class="pt-0" cols="12">
                        <v-card-title class="font-weight-bold pt-3 text-h5">VELO SALE
                        </v-card-title>
                    </v-col>
                    <v-col cols="12" md="8">
                        <v-text-field hide-details outlined v-model="filter"
                            label="Suche nach Fahrrädern"></v-text-field>
                    </v-col>
                    <v-col align="center" v-if="!$vuetify.breakpoint.mdAndUp" cols="12">
                        <v-btn @click="showFilters = !showFilters" text class="mb-3 mx-auto">
                            {{ showFilters ? 'Filter ausblenden' : 'Filter anzeigen' }}
                        </v-btn>
                        <div v-if="showFilters">
                            <v-select class="mb-5" hide-details filled v-model="selectedCategory" :items="categories"
                                label="Kategorie" clearable></v-select>
                            <v-select class="mb-5" hide-details filled v-model="selectedBrand" :items="brands"
                                label="Marke" clearable></v-select>
                            <v-select class="mb-5" hide-details filled v-model="selectedHeight" :items="heights"
                                label="Geeignete Körpergrösse" clearable></v-select>
                            <v-select class="mb-5" hide-details filled v-model="selectedSize" :items="sizes"
                                label="Grösse" clearable></v-select>
                        </div>
                    </v-col>
                    <v-col :class="{ 'px-0': $vuetify.breakpoint.mdAndUp }" class=" d-flex" align="center"
                        justify="center" cols="6" md="2">
                        <v-select class="mt-0" :disable-lookup="true" hide-details text v-model="sortOption"
                            :items="sortOptions" label="Sortieren nach"></v-select>
                    </v-col>
                    <v-col class="d-flex align-center justify-end" cols="6" md="2">
                        <v-btn-toggle class="my-auto" v-model="viewMode" dense>
                            <v-btn large value="compact" text icon>
                                <v-icon>mdi-view-compact</v-icon>
                            </v-btn>
                            <v-btn large value="full" text icon>
                                <v-icon>mdi-view-grid</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-col>

                    <v-col cols="12" sm="6" md="4" v-for="(bike, index) in paginatedBikes" :key="bike._id">
                        <v-card class="fill-height">
                            <v-img position="top" contain
                                :src="`/api/bikes/image/${bike.brandName}/${bike.imageFileName}`" height="150px"
                                alt="Bike Image">
                            </v-img>

                            <div :style="$vuetify.breakpoint.mdAndUp && viewMode === 'full' ? 'min-height: 130px' : ''">
                                <v-card-title v-if="viewMode === 'full'"
                                    :class="{ 'text-center': !$vuetify.breakpoint.mdAndUp }">{{
                                        bike.description
                                    }}</v-card-title>
                                <v-card-title v-else
                                    :class="{ 'text-center justify-center d-flex': !$vuetify.breakpoint.mdAndUp }">{{
                                        bike.brandName
                                    }}</v-card-title>
                                <v-card-subtitle :class="{ 'text-center': !$vuetify.breakpoint.mdAndUp }" class="pb-0">
                                    {{ bike.category }} - {{ bike.size }}
                                </v-card-subtitle>
                            </div>

                            <v-card-text class="pb-0">
                                <p :class="{ 'text-right mr-2': $vuetify.breakpoint.mdAndUp, 'text-center': !$vuetify.breakpoint.mdAndUp }"
                                    class=" mb-0 ">

                                    <span class="text-danger  text-h6 text-strike">CHF {{ bike.originalPrice
                                        }}.-</span><br />
                                </p>
                                <p class="text-center">
                                    <strong class="text-success text-h4 ">CHF {{ bike.discountPrice
                                        }}.-</strong>
                                </p>
                                <p class="text-center">
                                    <v-btn class="mx-auto" text small @click="showMoreInfoToggle(bike)">
                                        <span v-if="!bike.showMoreInfo">Mehr Infos</span>
                                        <span v-else>Weniger Infos</span>
                                    </v-btn>
                                </p>
                                <div v-if="bike.showMoreInfo">
                                    <p v-if="viewMode === 'compact'">Bezeichnung: {{ bike.description }}</p>
                                    <p>Marke: {{ bike.brandName }}</p>
                                    <p>Geeignete Körpergrösse: {{ bike.suitableHeight }}</p>
                                    <p>Grösse: {{ bike.size }}</p>
                                </div>

                                <p class="text-center mb-0" v-if="bike.isOwned">Du hast {{ bike.ownedAmount }}
                                    reserviert.
                                </p>
                                <p class="text-center text-danger mb-0" v-else-if="bike.isSoldOut">Nicht verfügbar
                                </p>
                                <p v-show="viewMode === 'full'" class="text-center mb-0" v-else> noch {{
                                    bike.availability }} verfügbar</p>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-center flex-column">
                                <template v-if="bike.isOwned" >
                                  
                                 <v-chip class="white--text mb-3" :color="statusMapColor[bike.orders[0].status]">Status: {{statusMap[bike.orders[0].status]}}</v-chip>
                                    <v-btn class="mx-auto" color="error" @click="cancelReservation(bike)">Reservierung
                                        auflösen</v-btn> 
                                
                                </template>
                                <template v-else-if="!bike.isSoldOut">
                                    <v-row>
                                        <v-col v-if="viewMode === 'full'" align="center" cols="12">
                                            <v-select style="max-width: 200px" hide-details filled
                                                v-model="bike.amountToOrder" :items="amountOptions(bike.availability)"
                                                label="Menge" clearable>
                                            </v-select>
                                        </v-col>
                                        <v-col class="mb-5" align="center" cols="12">
                                            <v-btn large color="primary" @click="showOrderBikeDialog(bike)">
                                                Reservieren
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
                <!-- Mehr laden Button -->
                <v-row style="margin-bottom: 100px">
                    <v-col align="center" class="mb-2 pb-0" cols="12">
                        <v-select hide-details style="max-width: 100px" v-model="itemsPerPage"
                            :items="[10, 20, 30, 40, 50, 'Alle']" label="Pro Seite anzeigen"></v-select>
                    </v-col>
                    <v-col cols="12" align="center" v-if="itemsToShow < filteredBikes.length">
                        <v-btn class="white--text" color="#313131" @click="loadMore">Mehr laden</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-dialog overlay-opacity="0.8" max-width="500" :value="forceVerifyEmail" persistent>
            <v-card>
                <v-card-title class="primary white--text">Verifiziere deine E-Mail</v-card-title>
                <v-btn v-if="false" absolute top right icon color="white" @click="logout">
                    <v-icon>mdi-logout-variant</v-icon>
                </v-btn>
                <verify-email @verifying="verifying = true" @emailVerified="emailVerified"></verify-email>
                <v-card-actions class="justify-content-center">
                    <v-btn color="primary" v-if="!verifying" @click="$router.push('/')">Zur Startseite</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog overlay-opacity="0.8" max-width="500" :value="showNoMembershipDialog" persistent>
            <v-card>
                <v-card-title class="primary white--text">Keine Mitgliedschaft gefunden</v-card-title>
                <v-card-text class="mt-5">
                    Wir haben leider keine Mitgliedschaft für die angegebene E-Mail-Adresse ({{ userService.getEmail()
                    }})
                    gefunden.
                    Das könnte daran liegen, dass du in der Roody-App vielleicht eine andere E-Mail-Adresse verwendet
                    hast,
                    als die, die in deinem Vertrag hinterlegt ist. Schnapp dir einfach die Trainer in deinem Studio,
                    die bringen das im Handumdrehen in Ordnung. <br><br>Falls du noch keine Mitgliedschaft hast, kannst
                    du
                    direkt
                    hier online eine abschliessen:
                </v-card-text>
                <v-card-actions class="flex-column d-flex">
                    <v-btn color="primary" class="mb-5" @click="openOnlineAbo">
                        Online Abo abschliessen
                    </v-btn>
                    <v-btn text @click="$router.push('/')">
                        Zur Startseite
                    </v-btn>
                </v-card-actions>
            </v-card>
            
        </v-dialog>
        <v-dialog persistent max-width="500px" :value="selectedBike !== null">
            <v-card>
                <v-card-title class="primary white--text">Reservation</v-card-title>
                <v-card-text class="mt-5">
                    Bitte bestätige die Reservation. Dein Velo wird vorläufig reserviert, 
            der Kauf ist damit jedoch noch nicht abgeschlossen. 
            Wir werden uns umgehend mit dir in Verbindung setzen, 
            um die nächsten Schritte zu besprechen.
                </v-card-text>
                <v-card-actions > 
                    <v-btn text @click="selectedBike = null">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="orderBike">Bestätigen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog max-width="500px" v-model="showCancelDialog">
            <v-card>
                <v-card-title class="primary white--text">Stornierung der Reservation</v-card-title>
                <v-card-text class="mt-5">
                    Um die Bestellung zu stornieren, nimm bitte Kontakt mit uns auf
                </v-card-text>
                <v-card-actions class="d-flex justify-center">
                    <v-btn text @click="showCancelDialog = false">Zurück</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import api from '@/api';
import userService from '@/services/userService';
import VerifyEmail from '@/components/verification/verifyEmail.vue'

export default {
    components: { VerifyEmail },
    data() {
        return {
            selectedBike: null,
            statusMapColor: {
                'open': "orange",
                'confirmed': "primary",
                'canceled': "red",
            },
            statusMap: {
                'open': "offen",
                'confirmed': "bestätigt",
                'canceled': "abgebrochen",
            },
            showCancelDialog: false,
            userService,
            showNoMembershipDialog: false,
            verifying: false,
            forceVerifyEmail: false,
            bikes: [],
            filter: '',
            selectedCategory: null,
            selectedBrand: null,
            selectedHeight: null,
            selectedSize: null,
            categories: [],
            brands: [],
            heights: [],
            sizes: [],
            itemsToShow: 20, // Standard: 10 anzeigen
            itemsPerPage: 20, // Anzahl pro Seite
            userId: '', // ID des eingeloggten Benutzers
            viewMode: 'full',
            showFilters: false,
            sortOption: null,
            sortOptions: [
                { text: 'Nach Rabatt', value: 'discount' },
                { text: 'Preis (aufsteigend)', value: 'priceAsc' },
                { text: 'Preis (absteigend)', value: 'priceDesc' },
                { text: 'Alphabetisch (A-Z)', value: 'alphaAsc' },
                { text: 'Alphabetisch (Z-A)', value: 'alphaDesc' },
            ],
        };
    },
    watch: {
        itemsPerPage(newVal) {
            if (newVal === 'Alle') {
                this.itemsToShow = this.filteredBikes.length; // Zeige alle Bikes
            } else {
                this.itemsToShow = parseInt(newVal); // Setze die Anzahl der anzuzeigenden Bikes
            }
        }
    },
    computed: {
        filteredBikes() {
            const bikes = this.bikes
                .map(bike => {
                    const validOrders = bike.orders.filter(order => order.status !== 'canceled');
                    const totalOrdered = validOrders.reduce((sum, order) => sum + order.amount, 0);

                    const userOrder = bike.orders.find(order => order.user === this.userId && order.status !== 'canceled');
                    const ownedAmount = userOrder ? userOrder.amount : 0;

                    const availability = bike.quantity - (totalOrdered - ownedAmount);
                    return {
                        ...bike,
                        availability: availability > 0 ? availability : 0,
                        isSoldOut: availability <= 0 && !userOrder,
                        isOwned: !!userOrder,
                        ownedAmount,
                        discount: bike.originalPrice - bike.discountPrice, // Rabatt berechnen
                    };
                })
                .filter(bike => {
                    return (
                        (!this.filter || bike.description.toLowerCase().includes(this.filter.toLowerCase())) &&
                        (!this.selectedCategory || bike.category === this.selectedCategory) &&
                        (!this.selectedBrand || bike.brandName === this.selectedBrand) &&
                        (!this.selectedHeight || bike.suitableHeight === this.selectedHeight) &&
                        (!this.selectedSize || bike.size === this.selectedSize)
                    );
                });

            // Sortieren nach `sortOption`
            if (this.sortOption) {
                switch (this.sortOption) {
                    case 'discount':
                        bikes.sort((a, b) => b.discount - a.discount);
                        break;
                    case 'priceAsc':
                        bikes.sort((a, b) => a.discountPrice - b.discountPrice);
                        break;
                    case 'priceDesc':
                        bikes.sort((a, b) => b.discountPrice - a.discountPrice);
                        break;
                    case 'alphaAsc':
                        bikes.sort((a, b) => a.description.localeCompare(b.description));
                        break;
                    case 'alphaDesc':
                        bikes.sort((a, b) => b.description.localeCompare(a.description));
                        break;
                }
            }

            return bikes.sort((a, b) => {
                // Eigene Bestellungen zuerst
                if (a.isOwned && !b.isOwned) return -1;
                if (!a.isOwned && b.isOwned) return 1;

                return 0; // Keine zusätzliche Sortierung hier notwendig
            });
        },

        paginatedBikes() {
            if (this.itemsPerPage === 'Alle') {
                return this.filteredBikes;
            }
            return this.filteredBikes.slice(0, this.itemsToShow);
        }
    },
    methods: {
        openOnlineAbo() {
            window.open('https://app.wellcomefit.ch/contract', '__blank')
        },
        async emailVerified() {
            this.forceVerifyEmail = false
            let fetchedTrans = await api.fetchAccountTransactionsForUser()
            if (fetchedTrans.error) {
                this.showNoMembershipDialog = true
            } else {
                this.fetchBikes();
            }

        },
        goToRoody() {
            this.$router.push('/')
        },
        showMoreInfoToggle(bike) {
            bike.showMoreInfo = !bike.showMoreInfo;
            this.$forceUpdate();
        },
        async fetchBikes() {
            console.log("fetching bikes")
            try {
                const response = await api.fetchBikes();
                this.bikes = response.data.map(bike => ({
                    ...bike,
                    amountToOrder: 1, // Standardmässig auf 1 setzen
                    showMoreInfo: false,
                }));
                this.categories = [...new Set(this.bikes.map(bike => bike.category))];
                this.brands = [...new Set(this.bikes.map(bike => bike.brandName))];
                this.heights = [...new Set(this.bikes.map(bike => bike.suitableHeight))];
                this.sizes = [...new Set(this.bikes.map(bike => bike.size))];
            } catch (error) {
                this.$toast.error('Fehler beim Laden der Fahrräder.');
            }
        },
        amountOptions(availability) {
            return Array.from({ length: availability }, (_, i) => i + 1);
        },
        loadMore() {
            if (this.itemsPerPage === 'Alle') return;
            this.itemsToShow += parseInt(this.itemsPerPage);
        },
        viewOrders() {
            this.$router.push({ name: 'BikeOwnOrders' });
        },
        async showOrderBikeDialog(bike) {
            this.selectedBike = bike
        },
        async orderBike() {
            if (!this.selectedBike.amountToOrder || this.selectedBike.amountToOrder <= 0 || this.selectedBike.amountToOrder > this.selectedBike.availability) {
                this.$toast.error('Ungültige Bestellmenge.');
                return;
            }

            try {
                await api.createOrder(this.selectedBike._id, this.selectedBike.amountToOrder);
                this.selectedBike = null
                this.$toast.success('Fahrrad erfolgreich reserviert!');
                this.fetchBikes()
                this.$router.push({ name: 'BikeOwnOrders' }); // Nach der Bestellung zu Bestellungen leiten
            } catch (error) {
                this.$toast.error('Fehler bei der Reservierung.');
            }
        },
        async cancelReservation(bike) {
           try {
                const userOrder = bike.orders.find(order => order.user === this.userId && order.status !== 'canceled');
                if (!userOrder || userOrder.status === 'confirmed') {
                    this.$toast.error('Reservierung kann nicht storniert werden.');
                    return;
                }

                await api.cancelOrder(userOrder._id);
                this.$toast.success('Reservierung erfolgreich storniert.');
                this.fetchBikes();
            } catch (error) {
                this.$toast.error('Fehler beim Stornieren der Reservierung.');
            }
             
        }
    },
    async created() {
        let status = await api.fetchUserEmailVerificationStatus()
        this.userId = userService.getUserId(); // Hole die Benutzer-ID

        if (this.userId === '67531b0f15209b6ab5c1eaa8') {
            this.fetchBikes()
            return
        } 

        if (status.verified) {
            let fetchedTrans = await api.fetchAccountTransactionsForUser()
            if (fetchedTrans.error) {
                this.showNoMembershipDialog = true
            } else {
                this.fetchBikes();
            }

        } else {
            this.forceVerifyEmail = true
        }

    }
};
</script>

<style scoped>
.text-danger {
    color: red;
    font-weight: bold;
}

.text-success {
    color: green;
    font-weight: bold;
}

.text-strike {
    text-decoration: line-through;
}
</style>
